<template>
  <div>
  <div class="d-flex justify-content-between align-items-center mb-n2">
      <h4 style="font-weight: 500">Dépots</h4> 
      <mdb-btn
              class="float-right"
              tag="a"
              color="primary"
              icon="plus"
              size="sm"
              small
              @click="addOrEdit(null)"
              v-if="$gate.hasPermission(['create::depot'])"

            >
            </mdb-btn>
    </div>
    <hr class="" />
    <mdb-row>
      <mdb-col sm="12" md="12" lg="10" class="mb-4 mx-0 mx-auto">
        <mdb-card cascade>
          <mdb-view
            v-if="false"
            class="card-header-reversed pl-2 pt-1 pb-1 d-flex justify-content-between align-items-center"
            cascade
          >
            <div
              class="title-card-tec card-header-title text-primary color-primary-reversed"
              style="font-weight: 400"
            >
              Dépots
            </div>
            <mdb-btn
              class="float-right"
              tag="a"
              color="primary"
              icon="plus"
              size="sm"
              small
              @click="addOrEdit(null)"
            >
            </mdb-btn>
          </mdb-view>

          <mdb-card-body :class="'card-reversed'" cascade v-loading="loading">
            <mdb-row>
              <mdb-col sm="12" md="6" class="">
                <mdb-select
                  flipOnScroll
                  label="Nombre par page"
                  outline
                  class=""
                  @getValue="selected"
                  v-model="dataPagination.pagination.perPageOptions"
                />
              </mdb-col>
              <mdb-col sm="12" md="6" class="mb-n3">
                <mdb-input
                  inputClass="border-reversed color-normal-reversed"
                  labelClass=" color-normal-reversed"
                  icon="search"
                  outline
                  type="text"
                  v-model="dataPagination.searchQuery"
                  placeholder="Rechercher"
                  aria-label="Search"
                />
              </mdb-col>
            </mdb-row>

            <mdb-tbl responsiveMd >
              <mdb-tbl-head>
                <tr class="color-normal-reversed">
                  <th v-for="column in data.columns" :key="column.name">
                    {{ column.label }}
                  </th>
                </tr>
              </mdb-tbl-head>
              <mdb-tbl-body>
                <tr
                  class="tec-table-row color-normal-reversed"
                  v-for="row in queriedData"
                  :key="row.name"
                >
                  <td class="tec-table-cell">
                    <span
                      v-if="row.is_active === 1"
                      class="badge rounded-pill bg-success"
                      style="height: 10px; width: 10px; display: inline-block"
                    ></span>
                    <span
                      v-if="row.is_active === 0"
                      class="badge rounded-pill bg-danger"
                      style="height: 10px; width: 10px; display: inline-block"
                    ></span>
                    {{ row.designation }}
                  </td>
                  <td class="d-flex justify-content-end">
                    <el-dropdown trigger="click">
                      <span
                        class="el-dropdown-link"
                        style="cursor: pointer; font-weight: 500"
                      >
                        Actions<i class="el-icon-arrow-down el-icon--right"></i>
                      </span>
                      <el-dropdown-menu class="dropdown-el-tec" slot="dropdown">
                        <el-dropdown-item
                          v-if="$gate.hasPermission(['edit::article'])"
                          @click.native="addOrEdit(row)"
                          class="dropdown-item-el-tec"
                          icon="el-icon-edit"
                          >Modifier</el-dropdown-item
                        >
                        <el-dropdown-item
                          v-if="$gate.hasPermission(['delete::article'])"
                          @click.native="deleteDepot(row.id)"
                          class="dropdown-item-el-tec"
                          icon="el-icon-delete"
                          >Supprimer</el-dropdown-item
                        >
                      </el-dropdown-menu>
                    </el-dropdown>
                  </td>
                </tr>
              </mdb-tbl-body>
            </mdb-tbl>

            <div v-if="empty">
              <div
                class="d-flex justify-content-around align-items-center color-normal-reversed"
                style="padding: 50px"
              >
                <div>
                  <p>
                    Pour ajouter un nouveau depot, cliquez sur le bouton
                    <mdb-btn
                      class="z-depth-0"
                      style="cursor: inherit"
                      tag="a"
                      color="primary"
                      icon="plus"
                      size="sm"
                      small
                    ></mdb-btn>
                    en haut à votre droite.
                  </p>
                </div>
              </div>
            </div>

            <div class="d-flex justify-content-end">
              <tec-pagination
                v-model="dataPagination.pagination.currentPage"
                :per-page="parseFloat(dataPagination.pagination.perPage)"
                :total="dataPagination.pagination.total"
              ></tec-pagination>
            </div>
          </mdb-card-body>
        </mdb-card>
      </mdb-col>
    </mdb-row>
  </div>
</template>

<script>
import {
  mdbCard,
  mdbCardBody,
  mdbView,
  mdbBtn,
  mdbTbl,
  mdbTblHead,
  mdbTblBody,
  mdbRow,
  mdbCol,
  mdbSelect,
  mdbInput,
} from "mdbvue";
import TecPagination from "@/tba/components/Pagination";

export default {
  components: {
    mdbCard,
    mdbCardBody,
    mdbView,
    mdbBtn,
    mdbTbl,
    mdbTblHead,
    mdbTblBody,
    mdbRow,
    mdbCol,
    mdbSelect,
    mdbInput,
    TecPagination,
  },
  props: {
    empty: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    dataPagination: {
      type: Array,
      default: () => [],
    },
    data: {
      type: Array,
      default: () => [],
    },
    queriedData: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {};
  },
  methods: {
    addOrEdit(data = null) {
      this.$emit("add_or_edit", data);
    },

    selected(e) {
      this.$emit("per_page_selected", e);
    },

    deleteDepot(id) {
      this.$emit("delete_depot", id);
    },
  },
};
</script>
