<template>
    <div class="container-fluid">
        <list-desktop
            v-if="!$isMobile()"
            :empty="empty"
            :loading="loading"
            :data="data"
            :queriedData="queriedData"
            :dataPagination="dataPagination"
            v-on:add_or_edit="addOrEdit"
            v-on:search_q="searchBack"
            v-on:load_depots="loadDepots"
            v-on:delete_depot="deleteDepot"
            v-on:per_page_selected="nbrPerPageSelected"
        >
        </list-desktop>

        <list-mobile
            v-if="$isMobile()"
            :empty="empty"
            :loading="loading"
            :data="data"
            :queriedData="queriedData"
            :dataPagination="dataPagination"
            v-on:add_or_edit="addOrEdit"
            v-on:search_q="searchBack"
            v-on:load_depots="loadDepots"
            v-on:delete_depot="deleteDepot"
            v-on:per_page_selected="nbrPerPageSelected"
        >
        </list-mobile>
     

    <mdb-modal
        centered
        size="md" 
        direction="top"
        position="top"
        :show="modalDepot.show" 
        @close="modalDepot.show = false" 
    >
        <form @submit.prevent='modalDepot.edit ? update() : save()'>
        <mdb-modal-header>
            <mdb-modal-title>{{ modalDepot.title }}</mdb-modal-title>
        </mdb-modal-header>
        <mdb-modal-body class="modal-tec card-reversed color-normal-reversed">
                <div v-if="!modalDepot.edit" class="import-section d-flex justify-content-end">
                    <mdb-btn @click="importDepots()" color="info" size="sm" icon="file-upload" class="px-2">
                        Importer Dépots
                    </mdb-btn>
                </div>
                <fieldset>
                    <mdb-row>
                        <mdb-col sm="12" md="12">
                            <mdb-input wrapperClass="" inputClass="border-reversed color-normal-reversed" labelClass=" color-normal-reversed" 
                             :validation="$store.state.depot.errors.designation ? true :false" :invalidFeedback="$store.state.depot.errors.designation"
                                v-model="form.designation"
                                @change="designationFormatter"
                              size="lg" outline label="Désignation" ></mdb-input>
                        </mdb-col>
                        
                    </mdb-row>
                </fieldset>
                <fieldset>
                    <legend>Avancé</legend>
                    <mdb-row>
                        <mdb-col sm="12" md="12" class="" >
                            <label for="">Etat du dépot?</label>
                            <mdb-switch v-model="form.is_active" offLabel="Désactivé" onLabel="Activé"  />
                        </mdb-col>
                    </mdb-row>
                </fieldset>
                
        </mdb-modal-body>
            <mdb-modal-footer class="">
                <mdb-btn size="md" color="primary" type="submit" :disabled="modalDepot.btn">
                    <span v-if="!modalDepot.btn">Sauvegarder</span>
                    <span v-if="modalDepot.btn" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    <span v-if="modalDepot.btn" class="pl-2">Loading...</span>
                </mdb-btn>
                <mdb-btn size="md" outline="primary" @click="modalDepot.show = false">Fermer</mdb-btn>

            </mdb-modal-footer>
        </form>
    </mdb-modal>

    <!-- Import Depots -->
        <mdb-modal
            size="md" 
            direction="top"
            position="top"
            :show="modalDepotImport.show"
            @close="modalDepotImport.show =false "
        > 
            <form @submit.prevent="saveimportDepots()">
            <mdb-modal-header>
                <mdb-modal-title>{{ modalDepotImport.title }}</mdb-modal-title><br>
            </mdb-modal-header>
            <mdb-modal-body class="">
                <p> Télécharger un modèle de fichier Excel. <a @click="downloadModelDepot()" href="#"> Télécharger </a> <br>
                 </p>
                <el-upload
                    class="upload-demo mt-3"
                    ref="depotUpload"
                    :action="$store.state.url+'api/imports/depots'"
                    :headers="{
                    'Authorization':'Bearer '+$store.state.auth.token}"
                    :accept="'.xlsx'"
                    :limit="1"
                    :auto-upload="false"
                    :on-success="importSuccess"
                    :on-error="importError">
                    <el-button slot="trigger" size="medium" type="primary">Ajouter</el-button>
                    <!-- <el-button v-if="$store.state.auth.company.logo" style="margin-left: 10px;" size="small" type="danger" @click="deleteLogo">Supprimer</el-button> -->
                    <div class="el-upload__tip colo-reversed" slot="tip">N'envoyez que des fichiers Excel de type xlsx.</div>
                </el-upload>
            </mdb-modal-body>
            <mdb-modal-footer class="">
                <mdb-btn size="md" color="primary" type="submit"  :disabled="modalDepotImport.btn" >
                    <span v-if="!modalDepotImport.btn">Importer</span>
                    <span v-if="modalDepotImport.btn" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    <span v-if="modalDepotImport.btn" class="pl-2">Loading...</span>
                </mdb-btn>
                <mdb-btn size="md" outline="primary" @click="modalDepotImport.show = false">Fermer</mdb-btn>

            </mdb-modal-footer>
            </form>
        </mdb-modal>
    </div>
</template>

<script>
import ListDesktop from './modules/ListDesktop'
import ListMobile from './modules/ListMobile'
import download from "../../../services/download-fetch"

import {
    mdbBtn,
    mdbSwitch,
    mdbRow,mdbCol,
    mdbInput,
    mdbModal,mdbModalTitle,mdbModalBody,mdbModalHeader,mdbModalFooter,
} from 'mdbvue'
export default {
    name:"depots",
    components:{
        mdbBtn,
        mdbSwitch,
        mdbRow,mdbCol,
        mdbInput,
        mdbModal,mdbModalTitle,mdbModalBody,mdbModalHeader,mdbModalFooter,

        ListDesktop,ListMobile
    },

    data(){
        return {
            loading:false,
            dataPagination: {
                pagination: {
                    perPage: 10,
                    currentPage: 1,
                    perPageOptions: [
                        {text:"10",value:"10", selected: true},
                        {text:"25",value:"25"},
                        {text:"50",value:"50"},
                        {text:"100",value:"100"},
                    ],
                    total: 0,
                },
                searchQuery: "",
                propsToSearch: [
                    "designation",
                ],
            },

            empty:false,
            data:{
                columns: [
                    {
                        label: "Désignation",
                        field: "designation",
                        sort: true,
                    },
                    
                    {
                        label: "",
                        field: "action",
                        sort: true,
                    },
                ],
                rows:[]
            },

            form:{
                id:"",
                designation:"",
                is_active:true
            },

            modalDepotDetails: {
                show: false,
                edit: false,
                title: "Détails du  dépot",
                content: {},
            },
            modalDepot: {
                show: false,
                edit: false,
                title: "Ajouter un dépot",
                content: {},
                btn:false
            },
            modalDepotImport: {
                show: false,
                edit: false,
                title: "Importer des depots",
                content: {},
                btn:false
            },
        }
    },

    methods:{
        //Fonction de fonctionnement
        addOrEdit(data = null) {
            if (data instanceof Object) {
                this.modalDepot.title = "Modifier un dépot";
                this.modalDepot.content = data;
                this.modalDepot.edit = true;
                this.formRest(data);
            } else {
                this.modalDepot.title = "Ajouter un dépot";
                this.modalDepot.content = {};
                this.modalDepot.edit = false;
                this.cleanForm("depot");
            }

            this.modalDepot.show = true;
        },

        cleanForm(type) {
            if (type === 'depot') {
				this.form.id = ""
                this.form.designation = ""
                this.form.is_active = true
			}
        },

        formRest(data) {
            this.form.id = data.id
            this.form.designation = data.designation
            this.form.is_active = data.is_active === 1 ? true: false
        },

        details() {
            // 
        },

        async loadDepots () {
			this.$nprogress.start()
            this.loading = !this.loading
            await this.$store.dispatch('depot/findAll')
            .then(response => {
                this.$nprogress.done()
                this.loading = !this.loading
                if (response.data.depots.length === 0) {
                    this.empty = true
                }else{
                    this.empty = false
                }
                this.data.rows = response.data.depots
            }).catch((error) => {
				this.$nprogress.done()
                this.loading = !this.loading
                this.$notify({
                    message: error.response.data.message  || error.response.data.error,
                    type: 'danger'
                })
            })
        },

        async save() {
            this.$store.commit('depot/SET_CLEAN')
            this.modalDepot.btn = !this.modalDepot.btn

            await this.$store.dispatch('depot/save', this.form)
			.then((response) => {
				this.modalDepot.show = false
                this.modalDepot.btn = !this.modalDepot.btn
                    this.$notify({
                        message: response.data.message,
                        type: 'success'
                    })
				this.cleanForm('depot')
                this.loadDepots()
			})
			.catch((error) => {
                this.modalDepot.btn = !this.modalDepot.btn
                if (error.response.data.errors) {
                    this.$store.commit('depot/SET_DESIGNATION', error.response.data.errors.designation) 
                }

                this.$notify({
                    message: error.response.data.message  || error.response.data.error,
                    type: 'danger'
                })
			}) 
        },

        async update() {
            this.$store.commit('depot/SET_CLEAN')
            this.modalDepot.btn = !this.modalDepot.btn
			await this.$store.dispatch('depot/update', this.form)
			.then((response) => {
				this.modalDepot.show = false
                this.modalDepot.btn = !this.modalDepot.btn
                    this.$notify({
                        message: response.data.message,
                        type: 'success'
                    })
				this.cleanForm('depot')
                this.loadDepots()
			})
			.catch((error) => {
                this.modalDepot.btn = !this.modalDepot.btn
                if (error.response.data.errors) {
                    this.$store.commit('depot/SET_DESI', error.response.data.errors.designation)
                }
                this.$notify({
                    message: error.response.data.message  || error.response.data.error,
                    type: 'danger'
                })
			})  
        },

        async deleteDepot(id){

            let res = await this.$store.dispatch("swal/doYouDelete");
            if (res.isDismissed) {
                return;
            }
			await this.$store.dispatch('depot/delete',id)
                .then((response) => {
                    this.$notify({
                        message: response.data.message,
                        type: 'success'
                    })
                    this.loadDepots()
                })
                .catch(error =>{
                    this.$notify({
                        message: error.response.data.message  || error.response.data.error,
                        type: 'danger'
                    })
                })
			
		},

        async downloadModelDepot(){
            this.$nprogress.start()
            await download({
                isTh:0,
                method:"get",
                url:'/imports/depots/model',
                filename:"modeles_depots.xlsx"
            }).then(() => {
                this.$nprogress.done();
                this.$notify({
                    message: "Téléchargé avec succès",
                    type: 'success'
                })
            })
            .catch(() => {
                this.$nprogress.done();
                this.$notify({
                    message: "Echec durant le téléchargement",
                    type: 'danger'
                })
            })
        },
        
        importDepots(){
            this.modalDepot.show = false

            this.modalDepotImport.show = true
            // 
        },

        async saveimportDepots(){
            if (this.$refs.depotUpload.uploadFiles.length > 0) {
                this.modalDepotImport.btn = !this.modalDepotImport.btn
                await this.$refs.depotUpload.submit()
            }
        },

        async importSuccess(response){
            this.modalDepotImport.btn = !this.modalDepotImport.btn
            this.modalDepotImport.show = false
            await   this.loadDepots()
            this.$notify({
                message: response.message,
                type: 'success'
            })
        },
        importError(error){
            this.modalDepotImport.btn = !this.modalDepotImport.btn
            this.$notify({
                message: JSON.parse(error.message).message,
                type: 'danger'
            })
        },

        // Systeme de datatable
        totalCalcul () {
            return this.dataPagination.pagination.total = this.data.rows.length;
        },

        searchQ () {
            if (!this.dataPagination.searchQuery) {
                // return this.pagedData;
                this.totalCalcul()
                return new Error('Nothing To Search')
            }
            
            let result = this.data.rows.filter((row) => {
                let isIncluded = false;
                for (let key of this.dataPagination.propsToSearch) {
                    let rowValue = "";
                    rowValue = row[key].toString().toLowerCase();
                    if (rowValue.includes && rowValue.includes(this.dataPagination.searchQuery.toLowerCase())) {
                    isIncluded = true;
                    }
                }
                return isIncluded;
            });
            this.dataPagination.pagination.total = result.length;
            return result.slice(this.from, this.to);
        },

        nbrPerPageSelected(value){
            let nbr = value;
            
            return this.nbrPerPage(nbr)
        },

        nbrPerPage (nbr) {
           return this.dataPagination.pagination.perPage = nbr
        },

        designationFormatter(){
            this.form.designation = this.form.designation.toUpperCase()
        }
    },

    created() {
        this.loadDepots()    
    },

    computed: {
        pageData () {
            return this.data.rows.slice(this.from, this.to);
        },

        to() {
            let highBound = this.from + this.dataPagination.pagination.perPage;
            if (this.total < highBound) {
                highBound = this.total;
            }
            return highBound;
        },

        queriedData() {
            let result = this.searchQ();
            return (result instanceof Error) ? this.pageData : result;
        },

        from() {
            return this.dataPagination.pagination.perPage * (this.dataPagination.pagination.currentPage - 1);
        },

        total() { 
            return this.totalCalcul();
        },
    }
}
</script>