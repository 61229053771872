<template>
    <div>
        <depot></depot> 
    </div>
</template>

<script>
import Depot from './DepotIndex'
export default {
    metaInfo: {
      title: 'TECBILL APP',
      titleTemplate: '%s - DEPOTS',
      htmlAttrs: {
        lang: 'fr',
        amp: true
      }
    },
    components:{
        Depot,
    },
    data(){
        return {
            //
        }
    }
}
</script>